export const productConst = [
    {
        id: 1,
        name: 'HR2000+CG 光谱仪',
        english: 'HR2000+CG',
        image: require('@/assets/images/product/product1.png'),
        detailImg: require('@/assets/images/product/details1.png'),
        detailsArr:[],
        headList: [
            {label: '工程规格 ',prop: 'name',},
            {label: '尺寸',prop: 'size',},
            {label: '重量',prop: 'weight',},
            {label: '检测器类型',prop: 'type',},
            {label: '波长范围',prop: 'range',},
            {label: '积分时间',prop: 'time',},
            {label: '动态范围',prop: 'dynamic',},
            {label: '信噪比',prop: 'noise',},
            {label: '光栅',prop: 'grating',},
            {label: '狭缝',prop: 'slit',},
            {label: '光学分辨率',prop: 'power',},
            {label: '杂散光',prop: 'stray',},
            {label: '板载缓冲',prop: 'buffer',},
            {label: '光纤连接器',prop: 'fiber',},
            {label: '耗电量',prop: 'consume',},
            {label: '频闪功能',prop: 'stroboscopic',},
            {label: '接口',prop: 'interface',},
            {label: '温度',prop: 'temperature',},
            {label: '湿度',prop: 'humidity',},
        ],
        tableList: [
            {
                name: 'HR2000+CG',
                size: '148.6 x 104.8  x 45.1毫米（5.9 x 4.1 x 1.8英寸）',
                weight: '0.57千克（1.26磅)',
                type: 'Sony ILX511B',
                range: '200-1100nm(最佳范围200-1050nm)',
                time: '1毫秒 – 65秒',
                dynamic: '8.5 x 10-7（系统）；单次采集1300:1',
                noise: '250:1（全信号）',
                grating: 'HC-1',
                slit: '5微米',
                power: '1.0纳米FWHM',
                stray: '600纳米时小于0.05%；435纳米时小于0.10%',
                buffer: '无',
                fiber: 'SMA 905至单根光纤（0.22 NA）',
                consume: '220毫安，+5伏直流',
                stroboscopic: '2个可编程的选通信号（单个/连续）',
                interface: 'USB 2.0，480Mbps；双线RS-232；I2C双线串行总线',
                temperature: '储存温度-30°C至+70°C，运行温度-10°C至50°C',
                humidity:'0%-90%（无冷凝）'
            }
        ]
    },
    {
        id: 2,
        name: 'Maya2000 Pro光谱仪',
        english: 'Maya2000 Pro',
        image: require('@/assets/images/product/product2.png'),
        detailImg: require('@/assets/images/product/details2.png'),
        describe:'Maya2000 Pro光谱仪提供较高的灵敏度，可覆盖约165-1100纳米（具体波长范围，需依据不同光栅配置）。它们非常适合深紫外光（真空紫外光），紫外-可见光和可见-近红外光测量。在每个Maya2000 Pro光谱仪的内部，都有一个薄型背照式面阵 FFT-CCD探测器，它设置在一个稳健的、可配置的光具座上，光具座上设有低噪声电子器件。有各种配件和光具座供您选择。我们的应用销售工程师可以根据您的测量需要帮助您挑选配置',
        features: ['1. 强大 — 它是超灵敏光谱仪，用于荧光测量和拉曼测量，以及用于提高吸光度和透射率','2. 灵活 — 连接至Maya2000 Pro的配件，精确功能可触发','3. 灵敏 — 高性能薄型背照式面阵CCD','4. 节约成本— 成本比传统台式仪低很多，但性能却高得多'],
        headList: [
            {label: '工程规格 ',prop: 'name'},
            {label: '尺寸',prop: 'size'},
            {label: '重量',prop: 'weight'},
            {label: '类型',prop: 'type'},
            {label: '选件',prop: 'options'},
            {label: '量子效率',prop: 'quantum'},
            {label: '光谱范围',prop: 'range'},
            {label: '光学分辨率',prop: 'FWHM'},
            {label: '全信号信噪比',prop: 'ratio'},
            {label: '动态范围',prop: 'dynamic'},
            {label: '积分时间',prop: 'integration'},
            {label: '光纤连接器',prop: 'connector'},
            {label: '电源要求',prop: 'power'},
            {label: '触发模式',prop: 'mode'},
            {label: '频闪功能',prop: 'stroboscopic'},
            {label: '接口',prop: 'interface'},
            {label: '温度',prop: 'temperature'},
            {label: '湿度',prop: 'humidity'},
        ],
        tableList: [
            {
                name: 'Maya2000 Pro',
                size: '149 x 109.3 x 50.4 mm',
                weight: '0.96 kg',
                type: '薄型背照式，2D',
                options: 'Hamamatsu S10420（紫外-可见光应用的最佳选件）;Hamamatsu S11510 （可见-近红外光应用的最佳选件）',
                quantum: '75% peak @ 600 nm  (S10420;85% peak @ 700 nm (S11510)',
                range: '~165-1100 nm (S10420); ~400-1180 nm (S11510)',
                FWHM: '取决于光栅刻线密度和狭缝大小（提高多种选择）',
                ratio: '~450:1',
                dynamic: '15000:1 (typical)',
                integration: '7.2 ms -5秒',
                connector: 'SMA 905至数值孔径0.22的单根光纤',
                power: '500 mA @ +5 VDC',
                mode: '4种模式',
                stroboscopic: '有',
                interface: 'USB 2.0, RS-232',
                temperature: '0°C 至 +50°C（运行温度）',
                humidity: '0-90%（无冷凝）',
            }
        ]
    },
    {
        id: 3,
        name: 'MX2500+多通道光谱仪',
        english: 'MX2500+',
        image: require('@/assets/images/product/product3.png'),
        detailImg: require('@/assets/images/product/product3.png'),
        describe:'MX2500+多通道光谱仪由Maya 2000pro和HR2000+系列光谱仪组合选配而成，不同于普通光谱仪分辨率与光谱检测范围的相互制约关系，MX2500+最高分辨率优于0.1nm（FWHM），最大检测范围可覆盖180-1100nm，是一款能同时满足宽波段范围和高分辨率的多通道光谱仪。',
        features: ['1. 自由配置MX2500+的分辨率和波长覆盖范围；','2. 可组合HR或Maya系列光谱仪以适配不同应用需求；','3. 内建可编程时序模块，高精度控制外部设备工作时序；','4. 硬件级同步设计，确保各通道工作统一；','5. 独立控制电源和USB3.0通讯接口，稳定快速不易掉线；','6. 控制软件兼容WinXP及以上版本'],
        application: ['1. LIBS（激光诱导击穿光谱）','2. 受控热核聚变','低压等离子体光谱检测','3. 介质阻挡放电等离子体光谱检测','4. 等离子体加工过程分析','5. 高分辨率透反射测量或激光测量'],
        headList: [
            {label: '工程规格 ',prop: 'name'},
            {label: '光谱范围',prop: 'spectral'},
            {label: '通道数量',prop: 'channels'},
            {label: '波长分辨率',prop: 'Wavelength'},
            {label: '探测器类型',prop: 'Detector'},
            {label: '积分时间',prop: 'Integration'},
            {label: '触发延迟范围',prop: 'delayRange'},
            {label: '触发步进',prop: 'TriggerStep'},
            {label: '触发抖动',prop: 'triggerJitter'},
            {label: '触发电平',prop: 'TriggerLevel'},
            {label: '触发接口',prop: 'TriggerInterface'},
            {label: '数据接口',prop: 'interface'},
            {label: '功率',prop: 'power'},
            {label: '尺寸',prop: 'size'},
            {label: '重量',prop: 'weight'},
        ],
        tableList: [
            {
                name: 'MX2500+多通道光谱仪o',
                spectral: '180-1100nm',
                channels: '1~8通道',
                Wavelength: '＜0.1nm(FWHM) （依配置而定）',
                Detector: '线阵CCD或面阵CCD',
                Integration: '最短1ms',
                delayRange: '-450μs到 450μs',
                TriggerStep: '13.8ns',
                triggerJitter: '±10ns',
                TriggerLevel: 'TTL',
                TriggerInterface: 'SMA',
                interface: 'USB3.0',
                power: '<5A @5V',
                size: '460mm*150mm*165mm(8 通道)',
                weight: '7kg',
            }
        ]
    },
    {
        id: 4,
        name: 'Ocean HDX-微型光纤光谱仪',
        english: 'Ocean HDX',
        image: require('@/assets/images/product/product4.png'),
        detailImg: require('@/assets/images/product/details4.png'),
        describe:'Ocean HDX采用高清晰度光学系统，具有高通量、低杂散光和高热稳定性的特点。它的x平台电子产品包括强大的机载处理和通信，包括USB、千兆以太网、Wi-Fi、AP Wi-Fi和RS-232。',
        features: ['1. 高清晰度光学-提供高光学分辨率和卓越的峰对称性元素分析，等离子体监测和端点检测','2. 高通量- 适用于低光水平的应用，如荧光，生物发光，磷光','3. 低杂散光- 适用于颜色测量; 用高光学密度样品，如油墨和染料，对溶液中的分析物进行精确测量小，热稳定，兼容- 容易集成到许多工业应用的生产过程环境'],
        table:[
            {name:'OCEAN-HDX-UV-VIS',Spectral:'200-800',slit:'10',power:'0.73'},
            {name:'OCEAN-HDX-VIS-NIR',Spectral:'350-925',slit:'10',power:'0.70'},
            {name:'OCEAN-HDX-XR',Spectral:'200-1100',slit:'10',power:'1.13'}
        ],
        headList: [
            {label: '有线',prop: 'wired'},
            {label: '无线',prop: 'wireless'},
            {label: '探测器',prop: 'detector'},
            {label: '光谱范围',prop: 'SpectralRange'},
            {label: '可更换狭缝',prop: 'Replaceable'},
            {label: '狭缝接口',prop: 'interface'},
            {label: '选型配置',prop: 'configuration'},
            {label: '信噪比（单次扫描）',prop: 'ratio'},
            {label: '动态范围（单次扫描）',prop: 'dynamicRange'},
            {label: '积分时间',prop: 'integrationTime'},
            {label: '杂散光',prop: 'StrayLight'},
            {label: '板载缓存',prop: 'cache'},
            {label: '热稳定性',prop: 'thermal'},
            {label: 'A/D 转化',prop: 'AorD'},
            {label: '输入/输出',prop: 'Input'},
            {label: '触发模式',prop: 'TriggerMode'},
            {label: '连接器',prop: 'Connector'},
            {label: '储存温度',prop: 'temperature'},
            {label: '操作温度',prop: 'operating'},
            {label: '软件',prop: 'Software'},
            {label: '材料',prop: 'Science'},
            {label: '符合标准',prop: 'standard'},
            {label: '尺寸',prop: 'size'},
            {label: '重量',prop: 'weight'},
        ],
        tableList: [
            {
                wired: 'Ethernet, USB, RS-232',
                wireless: 'Wi-fi 802.11 a/b/g/n',
                detector: 'Back-thinned CCD',
                SpectralRange: '200-1100 nm',
                Replaceable: '10, 25, 50, 100 or 200 µm width options',
                interface: 'SMA 905 or FC/PC',
                configuration: '取决于配置; 0.61 to 0.9 nm (FWHM) with 10 μm slit (整个光谱范围的平均值)',
                ratio: '400:1',
                dynamicRange: '12000:1',
                integrationTime: '6 ms-10 seconds',
                StrayLight: '>3 AU',
                cache: '50,000 spectra',
                thermal: '/- 1 pixel from 0 to 40°C',
                AorD: '16 bit',
                Input: '8 x user programmable GPIOs',
                TriggerMode: '4 modes',
                Connector: 'USB, Gigabit Ethernet, 40 pin JAE DD4 connector',
                temperature: '-30 °C to 70 °C',
                operating: '0 °C to 40 °C',
                Software: 'OceanView, OmniDriver and SeaBreeze',
                Science: 'RoHS',
                standard: 'CE, FCC Part 15 Class A, ICES-003 Class A, 2014/53/EU, CISPR 11:2009 A1:2010, EMC 2014/30/EU and EN 61326-1:2013',
                size: '88.9 mm x 63.5 mm x 52.4 mm',
                weight: '400 g',
            }
        ]
    },
    {
        id: 5,
        name: 'QE Pro(FL)光谱仪',
        english: 'QE Pro(FL)',
        image: require('@/assets/images/product/product5.png'),
        detailImg: require('@/assets/images/product/product5.png'),
        features: ['1. 便携 – 体积小、轻质、基于光纤的光谱仪，适用于野外','2. 灵敏 – 高量子效率检测器','3. 高性能 – 信噪比大于1000:1','4. 稳定 – 制冷型探测器可实现低光探测和预防光谱失真'],
        headList: [
            {label: '工程规格',prop: 'name'},
            {label: '尺寸',prop: 'size'},
            {label: '重量',prop: 'weight'},
            {label: '探测器类型',prop: 'type'},
            {label: '探测器范围',prop: 'Range'},
            {label: '探测器量子效率',prop: 'efficiency'},
            {label: '分光镜的波长范围',prop: 'Wavelength'},
            {label: '分光镜的集成时间',prop: 'time'},
            {label: '分光镜的动态范围',prop: 'dynamic'},
            {label: '分光镜的信噪比',prop: 'Signal'},
            {label: '分光镜的光栅',prop: 'grating'},
            {label: '分光镜的狭缝',prop: 'slit'},
            {label: '光学分辨率',prop: 'resolution'},
            {label: '杂散光',prop: 'strayLight'},
            {label: '缓冲',prop: 'buffer'},
            {label: '光纤接头',prop: 'fiberConnector'},
            {label: '电源要求',prop: 'Powe'},
            {label: '频闪功能',prop: 'Stroboscopic'},
            {label: '电子接口',prop: 'Interface'},
            {label: '工作温度',prop: 'temperature'},
            {label: '湿度',prop: 'humidity'},
        ],
        tableList: [
            {
                name: 'QE Pro-FL',
                size: '182毫米（7.17英寸）x 110毫米（4.33英寸）x 47毫米（1.85英寸）',
                weight: '光谱仪：1.15千克（2.6磅）；电源：0.45千克（1磅）',
                type: '滨松科学级，薄型背照式，TEC制冷，1044 x 64元素CCD阵列',
                Range: '185至1100纳米',
                efficiency: '90%（峰值）',
                Wavelength: '350至1100纳米',
                time: '8毫秒至60分钟',
                dynamic: '~85,000:1',
                Signal: '系统：1000:1（单次采集）',
                grating: '14个光栅可用（H1–H14），HC1光栅',
                slit: '5微米、10微米、25微米、50微米、100微米或200微米宽的狭缝（或SMA/FC隔板无狭缝）',
                resolution: '0.14–7.7纳米（取决于光栅和入射孔径大小',
                strayLight: '600纳米时小于0.08%；435纳米时为0.4%',
                buffer: '15000光谱',
                fiberConnector: 'SMA 905和Ocean Optics FC',
                Powe: '电源电压：4.5–5.5伏',
                Stroboscopic: '连续和单一闪光',
                Interface: 'USB 2.0，480Mbps（USB 1.1兼容）；RS-232（5线）',
                temperature: '0°C – 50°C',
                humidity: '≤ 90%无凝结',
            }
        ]
    },
    {
        id: 6,
        name: 'SpectrumTEQ-EL电致发光量子效率测量系统',
        english: 'SpectrumTEQ-EL',
        image: require('@/assets/images/product/product6.png'),
        detailImg: require('@/assets/images/product/details6.png'),
        describe:'SpectrumTEQ-EL系列电致发光量子效率测量系统，可以针对发光器件的光电特性进行有效测量，系统搭配的QEpro光谱仪具有信噪比、低杂散光等特性，可确保测量结果得准确性；同时，系统配有强大的测试软件，对话框式的软件操作界面让测量过程变得更为简单。',
        features: ['1. 体积小巧：便于灵活使用及运输','2. 原位测量：可放至手套箱内，实现原位测量','3. 流程化操作：设备无需频繁校准'],
        application: ['1. 无机电致发光','2. 有机电致发光','3. 分子薄膜EL器件'],
        headList: [
            {label: '光谱仪 - 型号',prop: 'model'},
            {label: '光谱仪 - 光谱范围(nm)',prop: 'range'},
            {label: '光谱仪 - 信噪比',prop: 'noiseRatio'},
            {label: '光谱仪 - 分辨率',prop: 'resolving'},
            {label: '光谱仪 - 动态范围',prop: 'dynamic'},
            {label: '光谱仪 - AD位数',prop: 'ADnum'},
            {label: '积分球 - 尺寸',prop: 'size'},
            {label: '积分球 - 材质',prop: 'material'},
            {label: '源表',prop: 'Source'},
            {label: '光纤 - 芯径',prop: 'diameter'},
            {label: '校准灯 - 角度',prop: 'angle'},
            {label: '校准灯 - 亮度',prop: 'brightness'},
            {label: '校准灯 - 型号',prop: 'type'},
            {label: '校准灯 - 功率',prop: 'power'},
            {label: '软件',prop: 'Software'},
        ],
        tableList: [
            {
                model: 'QEPro / QE65Pro（可选）',
                range: '350-1100',
                noiseRatio: '1000:01:00',
                resolving: '2.5 nm (FWHM)',
                dynamic: '85000:1（QEPro单次采集）；25000:1（QE65Pro单次采集）',
                ADnum: '18-bit（QEPro）；16-bit（QE65Pro）',
                size: '3.3” / 1.5” ',
                material: 'Spectralon',
                Source: 'Keithley2400',
                diameter: '1000um（可更换其他芯径）',
                angle: '2 Pi ',
                brightness: '50 流明',
                type: 'HL-3-INT-CAL',
                power: '5W（电功率）',
                Software: 'SpectrumTEQ-EL专用软件',
            }
        ]
    },
    {
        id: 7,
        name: 'SpectrumTEQ-PL光致发光量子效率测量系统',
        english: 'SpectrumTEQ-PL',
        image: require('@/assets/images/product/product7.png'),
        detailImg: require('@/assets/images/product/details7.png'),
        describe:'SpectrumTEQ-PL系列光致发光量子效率测量系统，针对器件的光致发光特性进行有效测量，可在手套箱内完成搭建，无需将样品取出，即可完成光致发光量子效率的测试。系统搭配QE Pro光谱仪信噪比高、杂散光低，动态范围大，适合不同波段和强度的激发光和发射光测量。同时，系统配有强大的测试软件，向导式的软件操作逻辑让测试过程变的简单，迅速。',
        features: ['1. 体积小巧：便于灵活使用及运输','2. 原位测量：可放至手套箱内，实现原位测量','3. 流程化操作：设备无需频繁校准'],
        application: ['1. 无机光致发光','2. 有机电致发光','3. EL器件封装前体'],
        headList: [
            {label: '光谱仪 - 型号',prop: 'model'},
            {label: '光谱仪 - 光谱范围(nm)',prop: 'range'},
            {label: '光谱仪 - 信噪比',prop: 'noiseRatio'},
            {label: '光谱仪 - 分辨率',prop: 'resolving'},
            {label: '光谱仪 - 动态范围',prop: 'dynamic'},
            {label: '光谱仪 - AD位数',prop: 'ADnum'},
            {label: '积分球 - 尺寸',prop: 'size'},
            {label: '积分球 - 涂层材质',prop: 'material'},
            {label: '激发光源',prop: 'source'},
            {label: '光纤-芯径',prop: 'diameter'},
            {label: '校准灯-型号',prop: 'type'},
            {label: '校准灯-功率',prop: 'power'},
            {label: '软件',prop: 'Software'},
            {label: '样品皿',prop: 'dish'},
        ],
        tableList: [
            {
                model: 'QEPro/QE65Pro（可选）',
                range: '350-1100',
                noiseRatio: '1000:1 ',
                resolving: '2.5nm(FWHM)',
                dynamic: '85000：1（QEPro单次采集）；25000:1（QE65Pro单次采集）',
                ADnum: '18-bit（QEPro）；16-bit（QE65Pro）',
                size: '3.3"',
                material: 'Sperctralon',
                source: '1. 365-880nm光纤耦合高功率LED；强度可调; 2. 典型半峰全宽（FWHM)=14nm@405nm',
                diameter: '1000um（可更换其他芯径）',
                type: 'HL-3P-INT-CAL',
                power: '5W（电功率）',
                Software: 'Ocean QY专用软件',
                dish: '固/液/粉末专用样品皿',
            }
        ]
    },
    {
        id: 8,
        name: '高灵敏度NIRQuest + 近红外光谱仪',
        english: 'NIRQuest',
        image: require('@/assets/images/product/product8.png'),
        detailImg: require('@/assets/images/product/details8.png'),
        describe:'高灵敏度检测下限的性能优良的测量精度,特别是在低光条件下热电冷却到-20 C低暗电流高信噪比集成周期短,适合快速测量样品在生产线兼容我们的光源和配件,确保良好的实验设计。新增强的NIRQuest+提供高达2.5倍的灵敏度与类似的光谱仪。我们现在提供更多的近红外光谱仪，性能更好，适用于900-2500纳米的各种应用',
        features: ['1. 预配置模型-包括900-1700 nm (NIRQuest+1.7)、900-2200 nm (NIRQuest+2.2)和900-2500 nm (NIRQuest+2.5)','2. 高灵敏度-增强光学台和孔径设计，大大改善光谱仪的响应，允许更低的检测极限(LOD)','3. 低LOD -检测较弱的近红外光源和使用较短的积分时间来捕获必要的光谱','4. 热稳定性-热电冷却至-20℃以获得低暗电流性能'],
        application: ['1. 回收塑料识别','2. 食品与农业酒精生产中的甲醇检测','3. 谷物和其他作物的水分、蛋白质和脂肪分析','4. 太阳能和太阳模拟器输出的校正测量','5. 土壤和植被分析，进行环境监测'],
        headList: [
            {label: '检测器',prop: 'model'},
            {label: '狭缝',prop: 'range'},
            {label: '光栅',prop: 'noiseRatio'},
            {label: 'Order Sorting',prop: 'resolving'},
            {label: '外形尺寸',prop: 'size'},
            {label: '重量',prop: 'weight'},
            {label: '积分时间',prop: 'time'},
            {label: '光学分辨率',prop: 'dynamic'},
            {label: '波长范围',prop: 'ADnum'},
            {label: '暗噪声',prop: 'material'},
            {label: '动态范围',prop: 'source'},
            {label: '光纤连接器',prop: 'diameter'},
            {label: '信噪比',prop: 'type'},
        ],
        tableList: [
            {
                model: 'InGaAs线性阵列',
                range: '25 µm',
                noiseRatio: 'GRATING_#NIR1, GRATING_#NIR2, GRATING_#NIR3',
                resolving: 'OF1-RG830长通近红外滤波器(选配)',
                size: '182 x 110 x 47 mm',
                weight: '1.18 kg(无电源)',
                time: '1ms - 12s',
                dynamic: '3.13 nm FWHM (typical), 5.47 nm FWHM (typical), 6.25 nm FWHM (typical)',
                ADnum: '900nm - 2.5μm',
                material: '16 RMS counts @ 10 ms; 24 RMS counts @ 30 ms, 6 RMS counts @ 100 ms; 12 RMS counts @ 250 ms, 6 RMS counts @100 ms; 12 RMS counts @ 1000 ms',
                source: '7.5 x 10⁶ (system); 10000:1 for a single acquisition',
                diameter: 'SMA 905',
                type: '>15000:1 @ 100 ms integration; >13000:1 @ 1000 ms integration, >8000:1 @ 100 ms integration; >13000:1 @ 1000 ms integration, 10000:1 @ 100 ms integration',
            }
        ]
    },
    {
        id: 9,
        name: '一体化激光诱导击穿光谱仪——ACCULIBS 2500',
        english: 'ACCULIBS 2500',
        image: require('@/assets/images/product/product9.png'),
        detailImg: require('@/assets/images/product/details9.png'),
        describe:'海洋光学出品的ACCULIBS2500一体化激光诱导击穿光谱仪是适用于环境，材料，生化和艺术品鉴定领域的实时测量，多元素分析设备。得益于样品平台和保护气体的自动控制系统，ACCULIBS2500+可以轻松实现对含轻元素（原子序数≤12）的固态样品进行不同位置，甚至不同深度的元素成分分析，还可以自动进行目标样品的面扫描。新升级的激光和成像光路结构更稳定，彻底简化了仪器调试和使用的复杂步骤。整个系统由可以自由选配不同波段的光谱核心、软件功能及激光器，集成化程度高构成，广泛适用于实验室及检验领域。',
        features: ['1. 全自动控制','2. 光谱核心控制','3.元素分析'],
        application: ['1. 环境监测（土壤污染，工业生产）','2. 材料分析（金属，煤炭，塑料）','3. 艺术品鉴定（颜料，陶瓷，宝石）'],
        headList: [
            {label: '平移台行程范围',prop: 'model'},
            {label: '成像',prop: 'range'},
            {label: '气体控制',prop: 'noiseRatio'},
            {label: '触发抖动',prop: 'resolving'},
            {label: '波长范围',prop: 'size'},
            {label: '通道数',prop: 'weight'},
            {label: '光学分辨率',prop: 'time'},
            {label: '最短积分时间',prop: 'dynamic'},
            {label: '触发延迟',prop: 'ADnum'}
        ],
        tableList: [
            {
                model: '50x50x20mm, 可选配',
                range: '可选高倍微观视野',
                noiseRatio: '电磁阀控制',
                resolving: '±10ns',
                size: '180nm-1100nm(根据配置)',
                weight: '1~8通道',
                time: '0.1nm（FWHM）',
                dynamic: '1ms',
                ADnum: '±450μs'
            }
        ]
    },
    {
        id: 10,
        name: '中红外ATR光谱仪-Ocean MZ5',
        english: 'Ocean MZ5',
        image: require('@/assets/images/product/product10.png'),
        detailImg: require('@/assets/images/product/product10.png'),
        describe:'Ocean MZ5微型ATR光谱仪，测量范围覆盖1818-909cm-1（5.5-11μm）。这款完全独立的仪器集成了采样端口，光源和检测器，提供了一种相对于传统的FTIR光谱更加紧凑、快速和扩展性强的替代方案。 应用方向包括化学鉴别，食品和香料分析，环境测试和科学研究。Ocean MZ5作为一个完整的系统，无需使用任何外部设备，如光源或光纤等。 Ocean Mirror是系统附带的专用软件，用于测量放置在仪器顶部晶体表面上液体的吸光度和透过率。在不使用时，可使用旋转盖保护水晶采样平台。',
        application: ['1. 燃料分析: 生物柴油中的脂肪酸含量;辛烷值测试;汽油中添加乙醇的含量','2. 材料识别: 生物材料分析; 溶剂分析;聚合物分析','3. 农场到餐桌技术: 农业测量和监测; 食品和饮料质量控制,食品安全', '4. 防伪鉴定: 测试和评估; 精油的鉴定和鉴伪'],
        headList: [
            {label: '光谱范围',prop: 'model'},
            {label: '信噪比',prop: 'range'},
            {label: '光谱带宽（FWHM）',prop: 'noiseRatio'},
            {label: '测量时间（典型值）',prop: 'resolving'},
            {label: '操作环境',prop: 'size'},
            {label: '存储环境',prop: 'weight'},
            {label: '重量',prop: 'time'},
            {label: '尺寸',prop: 'dynamic'},
            {label: '外壳和ATR框架材料',prop: 'ADnum'},
            {label: 'ATR表面积',prop: 'material'},
            {label: '晶体材料',prop: 'source'},
            {label: '水晶盖',prop: 'diameter'},
            {label: '样品反射次数',prop: 'type'},
            {label: '照明光源',prop: 'MEMS'},
            {label: '光源寿命',prop: 'life'},
            {label: '分散元件',prop: 'Dispersion'},
            {label: '检测器',prop: 'detector'},
            {label: '检测器',prop: 'converter'},
        ],
        tableList: [
            {
                model: '1818-909 cm-1（5.5-11μm）',
                range: '> 300：1（60 s测量',
                noiseRatio: '75 cm-1',
                resolving: '~30秒',
                size: '0-45°C非冷凝',
                weight: '0-60°C无冷凝',
                time: '~812克',
                dynamic: '165 mm x 165 mm x 66 mm',
                ADnum: '铝',
                material: '17 mm x 27 mm',
                source: 'ZnSe（硒化锌）',
                diameter: '保护样品测量区域',
                type: '9',
                MEMS: '电调MEMS发射器',
                life: '> 5,000小时（ 连续使用）',
                Dispersion: '线性可变滤波器',
                detector: '128像素非制冷热电阵列',
                converter: '16bit',
            }
        ]
  },
  {
      id: 11,
      name: '高电压电化学工作站200V',
      english: '',
      image: require('@/assets/images/product/product11.jpg'),
      detailImg: '',
      describe:'',
      application: [],
      headList: [],
      tableList: []
  }
]