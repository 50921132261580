<template>
    <div class="product">
        <div class="product_img">
            <img class="" :src="backImg" alt="">
        </div>
        <div class="pro_wrap martop20">
            <div class="proList main pad20">
                <div class="product_item" v-for="(item,index) in productArr" :key="index" @click="goDetail(index)">
                    <div class="images">
                        <img :src="item.image" alt="">
                    </div>
                    <div class="name">{{item.name}}</div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import {productConst} from '@/data/index'
export default {
    data(){
        return{
            current:0,
            backImg: require('@/assets/images/banner/products.jpg'),
            productNav:[
                {id:1,name:'产品1号'},
                {id:2,name:'产品2号'},
                {id:3,name:'产品3号'}
            ],
            productArr:[]
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.productArr = productConst
        }); 
    },
    methods:{
        handleNav(index){
            this.current = index
        },
        goDetail(index){
          let url = 'https://www.ips-jaissle.cn/chanpinzhongxin/46.html'
          if(index == 10){
            window.open(url)
          }else{
            this.$router.push('/detail?index='+(index+1))
          }
        }
    }
}
</script>
<style lang="scss" scoped>
.product{
    // background: #f6fbff;
    padding-bottom: 50px;
    .martop20{
        margin-top: 40px;
    }
    
    .main{
        width:1200px;
        margin:0 auto;
        background: #fff;
    }
    .pro_wrap{
        .proList{
            display: flex;
            flex-direction: row;
            flex-wrap:wrap;
            .product_item{
                border:1px solid #ccc;
                width:285px;
                margin: 0 13px 15px 0;
                &:hover{
                    box-shadow: 0px 6px 8px 2px rgba(8, 15, 36, 0.1);
                }
                .images{
                    overflow: hidden;
                    width:285px;
                    height: 285px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        width:100%;
                    }
                }
                .name{
                    height:68px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: 68px;
                    border-top: 1px solid #ccc;
                    padding: 0 10px;
                    box-sizing: border-box;
                    text-align: center;
                }
            }
        }
        
    }
}
</style>